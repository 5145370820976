<template>
  <div class="bg">
    <div v-if="!loadingData" class="row mt-3">
      <div class="col-md-10 col-sm-12">
        <div class="row">
          <div class="col-sm-12 col-md-12 ml-5">
            <h1 class="title gray">
              Olá {{ helper.quebrarNome(pagamento.nome)[0] }}
            </h1>
            <h5 class="sub-title">
              Finalize o pagamento para ativar sua assinatura Bike Registrada, que ainda está pendente.
            </h5>

            <div class="row mt-3 desktop">
              <div class="col-md-12 dashed">
                <span class="bold">Atenção: </span>Após a confirmação do pagamento, seus beneficios estarão disponiveis
                para uso
              </div>
            </div>
            <div class="d-flex dashed mobile mt-3">
              <img class="mr-3" src="/img/info-outlined.svg" />
              Após a confirmação do pagamento, seus beneficios estarão disponiveis para uso
            </div>
            <h6 class="sub-title bold mt-4">
              Por favor confirme os dados abaixo e insira a forma de pagamento
              desejada. Rápido e fácil!
            </h6>

            <div class="row desktop mt-5">
              <div class="col-md-6">
                <div class="card-checkout">
                  <h4 class="card-checkout-title bold">Pagamento do <span
                      class="card-checkout-title bold">Plano</span><span class="badge badge-secondary ml-3">{{
                        pagamento.nomePlano
                      }}</span></h4>

                  <div class="flex p-3">
                    <div class="price-checkout big">
                      <div class="medium">R$</div>
                      <div>
                        {{ formatacaoMoeda(separarNumero(pagamento.valor)) }}
                      </div>
                      <div class="medium">
                        ,{{ separarNumeroDecimal(pagamento.valor) }}
                      </div>
                    </div>
                    <div class="self-center">
                      <span class="badge badge-secondary">{{ tipoAssinatura[pagamento.tipoAssinatura] }}</span>
                    </div>
                  </div>
                  <div class="d-flex">
                    <!-- <button class="continue-btn" @click.prevent="openNavPix">
                      Pagar via PIX
                    </button> -->
                    <button class="continue-btn ml-3" @click.prevent="openNav">
                      Pagar com cartão de crédito
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-checkout mobile">
              <h4 class="card-checkout-title bold">Pagamento do <span class="card-checkout-title bold">Plano</span><span
                  class="badge badge-secondary mt-3">{{
                    pagamento.nomePlano
                  }}</span></h4>

              <div class="flex p-3">
                <div class="price-checkout big">
                  <div class="medium">R$</div>
                  <div>{{ formatacaoMoeda(separarNumero(pagamento.valor)) }}</div>
                  <div class="medium">
                    ,{{ separarNumeroDecimal(pagamento.valor) }}
                  </div>
                </div>
                <div class="self-center">
                  <span class="badge badge-secondary">{{ tipoAssinatura[pagamento.tipoAssinatura] }}</span>
                </div>
              </div>
              <div class="d-flex">
                <!-- <button class="continue-btn" @click.prevent="openNavPix">
                  Pagar via PIX
                </button> -->
                <button class="continue-btn" @click.prevent="openNav">
                  Pagar com cartão de crédito
                </button>
              </div>
            </div>

            <div class="row mb-5 mt-3">
              <div class="col-md-6">
                <div class="card-checkout dark-gray">
                  <h4 class="mb-3">
                    Dados do
                    <span class="card-checkout-title bold">Cliente</span>
                  </h4>
                  <div>
                    <span class="bold">Nome completo:</span> {{ pagamento.nome }}
                  </div>
                  <div><span class="bold">Email:</span> {{ pagamento.email }}</div>
                  <div>
                    <span class="bold">Celular:</span> {{ pagamento.celular }}
                  </div>
                  <div><span class="bold">CPF:</span> {{ pagamento.cpf }}</div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- CARTÃO CREDITO -->
    <div id="payment" class="sidenav">
      <div class="pb-5 pl-5 pr-5">
        <a href="javascript:void(0)" class="closebtn" @click.prevent="closeNav">&times;</a>
        <h3 class="gray bold mt-3">
          Faça o pagamento com seu cartão de crédito para finalizar a
          contratação.
        </h3>
        <ValidationObserver ref="formPagamento">
          <form id="formPagamento">
            <div class="row mt-5">
              <div class="col-sm-12 col-md-12">
                <ValidationProvider name="número do cartão" rules="required" v-slot="{ errors, classes }">
                  <div class="field mb-4">
                    <input type="tel" :style="numCartao && 'color: black;'" class="input" v-mask="'#### #### #### ####'"
                      :class="classes" name="cardNumber" v-model="numCartao" autocomplete="nope" />
                    <label :class="numCartao && 'filled'">Número do cartão</label>
                    <span class="invalid-feedback">{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </div>
              <div class="col-sm-12 col-md-6">
                <ValidationProvider name="validade" rules="required|min:5|expiracao_cartao"
                  v-slot="{ errors, classes }">
                  <div class="field mb-4">
                    <input type="tel" :style="validadeCartao && 'color: black;'" class="input" :class="classes"
                      v-mask="'##/##'" v-model="validadeCartao" autocomplete="nope" />
                    <label :class="validadeCartao && 'filled'">Validade</label>
                    <span class="invalid-feedback">{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </div>
              <div class="col-sm-12 col-md-6">
                <ValidationProvider name="cvc" rules="required|min:2" v-slot="{ errors, classes }">
                  <div class="field mb-4">
                    <input type="tel" class="input" :class="classes" name="cardCvc"
                      :style="cvcCartao && 'color: black;'" v-model="cvcCartao" autocomplete="nope" />
                    <label :class="cvcCartao && 'filled'">CVC</label>
                    <span class="invalid-feedback">{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </div>
              <div class="col-sm-12 col-md-8">
                <ValidationProvider name="nome" rules="required" v-slot="{ errors, classes }">
                  <div class="field mb-4">
                    <input type="text" class="input uppercase" :style="nomeCartao && 'color: black;'" :class="classes"
                      name="cardName" v-model="nomeCartao" maxlength="100" autocomplete="nope" />
                    <label :class="nomeCartao && 'filled'">Nome (como descrito no cartão)</label>
                    <span class="invalid-feedback">{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </div>

            </div>
          </form>
        </ValidationObserver>
        <div class="align-center mt-5">
          <button class="confirm-pay" @click="finalizarPagamento">
            <span>Finalizar pagamento</span>
          </button>
        </div>
      </div>
    </div>

    <!-- CARTÃO CREDITO MOBILE -->
    <div id="payment-mobile" class="sidenav">
      <div class="pb-5 pl-3 pr-3">
        <a href="javascript:void(0)" class="closebtn" @click.prevent="closeNav">&times;</a>
        <h5 class="green bold mt-3">
          Faça o pagamento com seu cartão de crédito para finalizar a
          contratação.
        </h5>
        <ValidationObserver ref="formPagamento">
          <form id="formPagamento">
            <div class="row mt-5">
              <div class="col-sm-12 col-md-12">
                <ValidationProvider name="número do cartão" rules="required" v-slot="{ errors, classes }">
                  <div class="field mb-4">
                    <input type="tel" :style="numCartao && 'color: black;'" class="input" v-mask="'#### #### #### ####'"
                      :class="classes" name="cardNumber" v-model="numCartao" autocomplete="nope" />
                    <label :class="numCartao && 'filled'">Número do cartão</label>
                    <span class="invalid-feedback">{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </div>
              <div class="col-sm-12 col-md-6">
                <ValidationProvider name="validade" rules="required|min:5|expiracao_cartao"
                  v-slot="{ errors, classes }">
                  <div class="field mb-4">
                    <input type="tel" :style="validadeCartao && 'color: black;'" class="input" :class="classes"
                      v-mask="'##/##'" v-model="validadeCartao" autocomplete="nope" />
                    <label :class="validadeCartao && 'filled'">Validade</label>
                    <span class="invalid-feedback">{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </div>
              <div class="col-sm-12 col-md-6">
                <ValidationProvider name="cvc" rules="required|min:2" v-slot="{ errors, classes }">
                  <div class="field mb-4">
                    <input type="tel" class="input" :class="classes" name="cardCvc"
                      :style="cvcCartao && 'color: black;'" v-model="cvcCartao" autocomplete="nope" />
                    <label :class="cvcCartao && 'filled'">CVC</label>
                    <span class="invalid-feedback">{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </div>
              <div class="col-sm-12 col-md-8">
                <ValidationProvider name="nome" rules="required" v-slot="{ errors, classes }">
                  <div class="field mb-4">
                    <input type="text" class="input uppercase" :style="nomeCartao && 'color: black;'" :class="classes"
                      name="cardName" v-model="nomeCartao" maxlength="100" autocomplete="nope" />
                    <label :class="nomeCartao && 'filled'">Nome (como descrito no cartão)</label>
                    <span class="invalid-feedback">{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </div>

            </div>
          </form>
        </ValidationObserver>
        <div class="align-center mt-5">
          <button class="confirm-pay" @click="finalizarPagamento">
            <span>Finalizar pagamento</span>
          </button>
        </div>
      </div>
    </div>

    <!-- PIX -->
    <div id="payment-pix" class="sidenav">
      <div class="pb-5 pl-5 pr-5">
        <a href="javascript:void(0)" class="closebtn" @click.prevent="closeNavPix">&times;</a>
        <div class="d-flex">
          <img src="/img/check-sucess-outline.svg" />
          <h3 class="green bold mt-3 ml-3">
            Seu pedido foi realizado com sucesso!
          </h3>
        </div>
        <div class="mt-5 d-flex waiting">
          <b-spinner label="Spinning" variant="success" style="width: 2rem; height: 2rem"></b-spinner>
          <h6 class="gray bold ml-3">Estamos aguardando o seu pagamento!</h6>
        </div>
        <div class="d-flex mt-5">
          <img src="/img/icon-info-filled.svg" />
          <h5 class="gray ml-3">
            Por favor abra o aplicativo do seu banco e efetue o pagamento do PIX
            fazendo a leitura do QRCode abaixo.
          </h5>
        </div>
        <div class="d-flex">
          <img src="/img/alert-filled-up.svg" />
          <h5 class="gray mt-3 ml-3">
            Por favor não saia desta página até efetuar o pagamento e visualizar
            a mensagem de sucesso!
          </h5>
        </div>
        <div class="row mt-3">
          <div class="col-md-12 dashed">
            <img src="/img/info-outlined.svg" /><span class="ml-2 bold">Importante: </span>O QRCode abaixo tem a
            validade de 10 minutos
          </div>
        </div>
        <div class="input-pix mt-3 d-flex">
          <input id="pix-copia-cola" type="text" class="b-none w-100" v-model="pix" readonly />
          <span class="mr-2">...</span>
          <img src="/img/copy-paste.svg" @click.prevent="copy" />
        </div>
        <div class="align-center mt-5">
          <img :src="urlQrCode" />
        </div>
      </div>
    </div>

    <!-- PIX MOBILE-->
    <div id="payment-pix-mobile" class="sidenav">
      <div class="pb-5 pl-3 pr-3">
        <a href="javascript:void(0)" class="closebtn" @click.prevent="closeNavPix">&times;</a>
        <div class="d-flex">
          <img src="/img/check-sucess-outline.svg" />
          <h5 class="green bold mt-3 ml-3">
            Seu pedido foi realizado com sucesso!
          </h5>
        </div>
        <div class="mt-3 align-center">
          <b-spinner label="Spinning" variant="success" style="width: 2rem; height: 2rem"></b-spinner>
          <h6 class="gray bold ml-3">Estamos aguardando o seu pagamento!</h6>
        </div>
        <div class="d-flex mt-5">
          <img src="/img/icon-info-filled.svg" />
          <h6 class="gray ml-3">
            Por favor abra o aplicativo do seu banco e efetue o pagamento do PIX
            fazendo a leitura do QRCode abaixo.
          </h6>
        </div>
        <div class="d-flex">
          <img src="/img/alert-filled-up.svg" />
          <h6 class="gray mt-3 ml-3">
            Por favor não saia desta página até efetuar o pagamento e visualizar
            a mensagem de sucesso!
          </h6>
        </div>
        <div class="row mt-3">
          <div class="col-md-12 dashed">
            <img src="/img/info-outlined.svg" /><span class="ml-2 bold">Importante: </span>O QRCode abaixo tem a
            validade de 10 minutos
          </div>
        </div>
        <div class="input-pix mt-5 d-flex">
          <input id="pix-copia-cola" type="text" class="b-none w-100" v-model="pix" readonly />
          <span class="mr-2">...</span>
          <img src="/img/copy-paste.svg" @click.prevent="copy" />
        </div>
        <div class="align-center mt-5">
          <img width="300" :src="urlQrCode" />
        </div>
      </div>
    </div>

    <Loading :criandoSeguro="true" v-if="loading" />
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import config from "../config";
import formatacaoMoeda from "../helpers/formatacaoMoeda";
import formatacaoMoeda2 from "../helpers/formatacaoMoeda2";
import formatacaoTelefone from "../helpers/formatacaoTelefone";
import helper from "../helpers/helper";
export default {
  components: {
    Loading: () => import("../components/Usuario/Loading.vue"),
  },
  data() {
    return {
      helper,
      config,
      loading: false,
      loadingData: false,
      formatacaoMoeda,
      formatacaoMoeda2,
      formatacaoTelefone,
      urlQrCode: undefined,
      pix: undefined,
      tipoAssinatura: {
        "e75ab49e-43f4-49bd-810a-784c1f063e0e": "Mensal",
        "76f0aa4a-5052-4c77-980a-86c33eff8a5a": "Anual"
      },
      idTipoAssinatura: undefined,
      idPagamento: undefined,
      pixPago: false,
      pagamento: {
        tipoAssinatura: "",
        nome: "",
        email: "",
        cpf: "",
        celular: "",
        valor: 0,
        status: "",
        nomePlano: undefined,
      },
      intervalPix: undefined,
      numCartao: undefined,
      validadeCartao: undefined,
      cvcCartao: undefined,
      nomeCartao: undefined,

      //Cupom
      numero: 0,
      decimal: 0,
      numeroOpicional: 0,
      decimalOpicional: 0,
      numeroTotal: 0,
      decimalTotal: 0,
    };
  },
  created() { },

  mounted() {
    this.buscar();
  },

  watch: {},

  methods: {
    carregarIugu() {
      if (window["Iugu"]) {
        window["Iugu"].setAccountID(config.IUGU_ACCOUNTB);
        window["Iugu"].setTestMode(config.IUGU_TEST_MODE);
      }
    },
    async buscar() {

      this.loading = true;
      this.loadingData = true;

      this.idPagamento = this.$route.params.id;

      try {
        if (!this.idPagamento) return (this.loadingData = false);

        var response = await axios.get(`${config.API_URLV2}/pagamentoAssinaturaRegistro/${this.idPagamento}`);

        if (response.status == 204) {
          this.loading = false;
          return await Swal.fire({
            text: "Fatura não foi encontrada",
            icon: "error",
            confirmButtonText: "Ok",
          });
        }

        this.pagamento = response.data;


        this.carregarIugu();

        this.loadingData = false;
        this.loading = false;

        return true;
      } catch (error) {
        this.loadingData = false;
        await Swal.fire({
          title: "Não foi possível buscar seu pagamento",
          text: "Tente novamente mais tarde ou contate o suporte.",
          icon: "error",
          confirmButtonText: "Ok",
        });
        return false;
      }
    },
    separarNumero(numero) {
      if (!numero) return;
      const partes = numero.toString().split(".");
      return partes[0];
    },
    separarNumeroDecimal(numero) {
      if (!numero) return;
      const partes = numero.toString().split(".");
      let valor = partes[1];
      if (valor?.length == 0 || valor == null) {
        valor = "00";
      }
      if (valor?.length == 1) {
        valor = valor.toString() + "0";
      }
      if (valor?.length > 1) {
        valor = valor.substring(0, 2);
      }

      return valor;
    },
    copy() {
      var copyText = document.getElementById("pix-copia-cola");

      copyText.select();
      copyText.setSelectionRange(0, 99999);

      navigator.clipboard.writeText(copyText.value);

      this.$toast.success("Copiado com sucesso ✅");
    },
    openNav() {
      if (document.body.clientWidth < 768) {
        document.getElementById("payment-mobile").style.width = "100%";
      } else {
        document.getElementById("payment").style.width = "50%";
      }
      this.formaPagamento = "C";
    },
    closeNav() {
      if (document.body.clientWidth < 768) {
        document.getElementById("payment-mobile").style.width = "0";
      } else {
        document.getElementById("payment").style.width = "0";
      }
      this.formaPagamento = null;
    },
    async openNavPix() {
      const pix = await this.buscarFatura();

      if (pix) {
        this.verificarPagamento();
      }
    },
    closeNavPix() {
      if (document.body.clientWidth < 768) {
        document.getElementById("payment-pix-mobile").style.width = "0";
      } else {
        document.getElementById("payment-pix").style.width = "0";
      }
      clearInterval(this.intervalPix);
    },

    async verificarPagamento() {
      if (document.body.clientWidth < 768) {
        document.getElementById("payment-pix-mobile").style.width = "100%";
      } else {
        document.getElementById("payment-pix").style.width = "50%";
      }

      await new Promise((resolve) => {
        this.intervalPix = setInterval(() => {
          if (this.pixPago) {
            clearInterval(this.intervalPix);
            return this.$router.replace("/pagamento-aprovado-registro");
          } else {
            resolve(this.informacaoPagamento());
          }
        }, 2000);
      });
    },
    async informacaoPagamento() {
      try {
        var response = await axios.get(
          `${config.API_URLV2}/pagamentoAssinaturaRegistro/status/${this.idPagamento}`
        );
        if (response.data == 'paid') {
          this.pixPago = true;
        }
      } catch (error) {
        this.loading = false;
        clearInterval(this.intervalPix);
        this.erroFinalizarPagamento(error);
        this.closeNavPix();
      }
    },

    async finalizarPagamento() {
      const valid = await this.$refs.formPagamento.validate();

      if (!valid) return;

      this.loading = true;
      try {
        var tokenCartaoIugu = await this.gerarHashCartao();

        var data = {
          id: this.idPagamento,
          tokenCartao: tokenCartaoIugu,
        };

        await axios.post(
          `${config.API_URLV2}/pagamentoAssinaturaRegistro/cartaoCredito`,
          data
        );

        this.loading = false;

        return this.$router.replace("/pagamento-aprovado-registro");
      } catch (error) {
        this.loading = false;
        this.erroFinalizarPagamento(error);
      }
    },

    async buscarFatura() {
      this.loading = true;

      try {
        var response = await axios.get(`${config.API_URLV2}/pagamentoAssinaturaRegistro/pix/${this.idPagamento}`);

        this.urlQrCode = response.data.urlQrCode;
        this.pix = response.data.pix;

        this.loading = false;

        return true;
      } catch (error) {
        this.loading = false;
        this.erroFinalizarPagamento(error);
        return false;
      }
    },
    erroFinalizarPagamento(error) {
      var errorMessage = error?.response?.data?.message;
      if (error?.response?.data?.error == "ok") {
        window.location.reload(true);
      } else if (errorMessage?.startsWith("Unexpected")) {
        this.loading = false;
        Swal.fire({
          title: "Não foi possível finalizar o seu pagamento",
          text: "Tente novamente mais tarde ou contate o suporte.",
          icon: "error",
          confirmButtonText: "Ok",
        });
      } else if (this.formaPagamento == null) {
        Swal.fire({
          title: "Não foi possível finalizar o seu pagamento",
          text: "Tente novamente mais tarde ou contate o suporte.",
          icon: "error",
          confirmButtonText: "Ok",
        });
      } else if (error?.response?.data?.error) {
        Swal.fire({
          title: "Atenção",
          html: `
  <div style="text-align: left;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;">
<p>Não conseguimos validar o seu cartão. Por favor verifique os dados informados e tente novamente.</p>
<p>Abaixo os principias motivos:</p>
1 - Seu cartão está bloqueado para compras On-line.<br>
2 - Seu cartão está vencido.<br>
3 - Seu cartão está desabilitado para compras.<br>
4 - Algum dado do cartão foi digitado errado.<br>
<p class="mt-3">Caso persista, por favor entre em contato com a operadora do seu cartão e tente novamente.</p></div>`,
          icon: "warning",
          confirmButtonText: "Ok",
        });
      } else if (
        errorMessage ||
        (error && error.toString().toLowerCase().indexOf("cartão") !== -1)
      ) {
        this.loading = false;
        Swal.fire({
          title: "Atenção",
          text: errorMessage || error.toString(),
          icon: "warning",
          confirmButtonText: "Ok",
        });
      } else {
        this.loading = false;

        Swal.fire({
          title: "Não foi possível finalizar o seu pagamento",
          text: "Tente novamente mais tarde ou contate o suporte.",
          icon: "error",
          confirmButtonText: "Ok",
        });
      }
    },

    verificarScriptIugu() {
      setTimeout(() => {
        var naoCarregouScriptsIugu = window["Iugu"] == undefined;

        if (naoCarregouScriptsIugu) {
          Swal.fire({
            icon: "error",
            title: "Atenção!",
            text: "Parece que seu navegador possui alguma extensão que está bloqueando o pagamento. Tente desabilitar as extensões ou tente acessar de outro navegador.",
          }).then(() => {
            window.location.reload();
          });
        }
      }, 3000);
    },
    obterValidadeCartao() {
      var mesCartao = 0;
      var anoCartao = 0;

      if (this.validadeCartao) {
        var partes = this.validadeCartao.split("/");
        mesCartao = partes[0];
        anoCartao = partes[1];
      }

      return [mesCartao, anoCartao];
    },
    gerarHashIuguAsync(dados) {
      return new Promise((resolve, reject) => {
        try {
          var cc = window["Iugu"].CreditCard(
            dados.number,
            dados.expirationMonth,
            dados.expirationYear,
            dados.firstName,
            dados.lastName,
            dados.cvc
          );

          var valido = cc.valid();

          if (valido == false) {
            reject("Cartão inválido. Por favor verifique os dados.");
          }

          window["Iugu"].createPaymentToken(cc, function (data) {
            if (data.errors) {
              reject(
                "Ocorreu um erro ao tentar processar o pagamento com seu cartão. Favor verificar os dados e confirmar se seu cartão está liberado para compras online."
              );
            } else {
              resolve(data.id);
            }
          });
        } catch (e) {
          reject("Erro ao criar token Iugu: " + JSON.stringify(e));
        }
      });
    },
    async gerarHashCartao() {
      var validadeCartaoArr = this.obterValidadeCartao();
      var hash = await this.gerarHashIuguAsync({
        number: this.numCartao.replace(/ /g, ""),
        expirationMonth: validadeCartaoArr[0],
        expirationYear: validadeCartaoArr[1],
        firstName: helper.quebrarNome(this.nomeCartao)[0],
        lastName: helper.quebrarNome(this.nomeCartao)[1],
        cvc: this.cvcCartao,
      });
      return hash;
    },
  },
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css?family=Open%20Sans:wght@100..900&display=swap");

.waiting {
  justify-content: center;
  align-items: center;
}

.b-none {
  border: none;
}

.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  background-color: #f9f9f9;
  overflow-x: hidden;
  /* overflow-y: hidden; */
  /* transition: 0.5s; */
  padding-top: 60px;
  box-shadow: 9px 12px 30px 0px rgba(0, 0, 0, 0.1),
    0 6px 20px 0 rgba(0, 0, 0, 0.1);
}

.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  /* transition: 0.3s; */
}

.sidenav a:hover {
  color: #f1f1f1;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 10px;
  font-size: 36px;
  color: #425994;
}

.mb-7 {
  margin-bottom: 7rem !important;
}

.uppercase {
  text-transform: uppercase;
}

#beneficios-mobile .flicking-pagination-bullet-active {
  background-color: #4fa935 !important;
  width: 12px !important;
  height: 12px !important;
}

#beneficios-mobile .flicking-pagination-bullet {
  background-color: #d9d9d9;
}

.flicking-pagination-bullet-active {
  background-color: #425994 !important;
}

.w-65 {
  width: 65%;
}

.h-100 {
  height: 100px !important;
}

.nowrap {
  text-wrap: nowrap;
}

.pointer {
  cursor: pointer;
}

.confetti {
  bottom: 0px;
  position: absolute;
  width: 60%;
}

.field {
  position: relative;
}

label {
  position: absolute;
  pointer-events: none;
  left: 15px;
  top: 22px;
  transition: 0.2s ease all;
  font-size: 14px;
  color: #78849e;
  font-weight: normal;
}

.filled {
  top: 2px !important;
}

input:focus~label {
  top: 2px;
  color: #4fa935;
  font-weight: bold;
}

.flicking-arrow-prev::before,
.flicking-arrow-prev::after,
.flicking-arrow-next::before,
.flicking-arrow-next::after {
  background-color: #425994 !important;
}

.flicking-arrow-next {
  right: -20px !important;
}

.flicking-arrow-prev {
  left: -20px !important;
}

.align-start {
  align-items: start;
}

.gift-text {
  color: #4fa935 !important;
}

.gift {
  list-style-image: url("/img/gift-icon-green.svg");
}

.gift-gray {
  list-style-image: url("/img/gift-icon-gray.svg");
}

.gift-white {
  list-style-image: url("/img/gift-icon-white.svg");
}

.item-benefit {
  color: #089f17 !important;
}

.chosen-item {
  color: #ffffff94 !important;
}

.card-checkout-plan {
  justify-content: space-between;
  display: flex;
}

.step-description {
  margin-top: 14px;
  text-wrap: nowrap;
  font-size: 12px;
}

.pipe {
  height: 5rem;
  border-left: 2px solid #e5e5e5;
}

.flex {
  display: flex;
}

.self-center {
  align-self: center;
}

.self-start {
  align-self: start;
}

.medium {
  padding-top: 23px;
  font-size: 1vw;
  font-weight: 800;
}

.big {
  font-size: 4rem !important;
}

.font-small {
  font-size: 0.8vw;
}

.card-optional-plan {
  display: flex;
  justify-content: space-between;
}

.space-between {
  justify-content: space-between;
}

.benefits-optional {
  padding: 10px;
}

.card-optional {
  padding: 12px;
}

.border-left-optional {
  border-left: 1px solid #e5e5e54d;
}

.optional-small {
  font-size: 24px;
  font-weight: 600;
}

.optional-price {
  display: flex;
  align-items: baseline;
  font-size: 48px;
  font-weight: 800;
  margin-top: 1rem;
}

.dark-gray {
  background-color: #78849e !important;
}

a {
  color: #089f17;
  text-decoration: underline;
  background-color: transparent;
}

.line {
  border-left: 1px solid #e5e5e5;
  height: 70px;
}

.line-optional-plan {
  border-left: 1px solid #e5e5e5;
  height: 9rem;
}

.badge-plan {
  padding: 5px 10px 5px 10px;
  font-weight: 500;
  border-radius: 8px;
  color: #425994;
  background-color: #e9e9e9;
}

.footer-plan {
  padding: 12px;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #78849e;
  color: white;
}

.footer-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tooltip-plan {
  cursor: pointer;
  position: relative;
  display: inline-block;
}

.benefit-title {
  font-size: 16px;
  font-weight: 700;
}

.benefit-content {
  font-size: 14px;
  font-weight: normal;
  font-family: Open Sans !important;
  color: #acb4be;
}

.tooltip-plan .tooltip-content {
  background-color: #2a2e43;
  color: #fff;
  text-align: left;
  padding: 36px;
  border-radius: 12px;
  position: absolute;
  z-index: 3;
  width: 130%;
}

.visible {
  visibility: visible;
}

.tooltip-content::after {
  content: "";
  position: absolute;
  top: 0%;
  left: 65%;
  right: 30%;
  bottom: 100%;
  margin-top: -25px;
  border-width: 15px;
  border-style: solid;
  border-color: transparent transparent #2a2e43 transparent;
}

.recom-word {
  text-align: center;
  font-weight: bold;
  color: #4fa935;
  margin-top: 0;
  margin-bottom: 0px;
}

.end {
  text-align: end;
}

.recommended {
  border: 1px solid #4fa935;
}

.list {
  margin-top: 8px;
}

.not-included {
  text-decoration: line-through;
}

.wiped {
  color: #d1d5dc;
}

.underline {
  text-decoration: underline;
}

.price-checkout {
  align-self: center;
  justify-content: center;
  display: flex;
  font-size: 5.2vw;
  font-weight: 800;
}

.price {
  justify-content: center;
  display: flex;
  font-size: xxx-large;
  font-weight: 800;
}

.small {
  padding-top: 15px;
  font-size: small;
  font-weight: 800;
}

.card-plan-itens {
  color: #425994;
  padding-inline-start: 20px !important;
}

.white {
  color: #ffffff;
}

.align-center {
  text-align: center;
}

.flex>h6,
h4 {
  margin-bottom: 0px !important;
}

.card-plan {
  cursor: pointer;
  color: #425994;
  padding: 24px;
  background-color: #ffffff;
  border-radius: 12px;
}

.card-checkout {
  color: #ffffff;
  padding: 24px;
  background-color: #4fa935;
  border-radius: 12px;
  min-height: 265px;
}

.chosen {
  color: #ffffff !important;
  background-color: #4fa935 !important;
}

.chosen-optional {
  color: #1e570d !important;
  background-color: #a6e894 !important;
}

.badge-secondary {
  padding: 5px 10px 5px 10px;
  border-radius: 12px;
  color: #425994;
  background-color: #e9e9e9;
}

.badge-chosen {
  padding: 5px 10px 5px 10px;
  border-radius: 12px;
  color: #425994;
  background-color: #ffffff;
}

.dashed {
  padding: 15px;
  border-radius: 12px;
  border: 2px dashed #78849e;
  background-color: #fff;
}

.dashed-optional {
  border: 1px dashed #78849e;
}

.screen {
  width: 100%;
}

.weight-600 {
  font-weight: 600;
}

.bg {
  background-image: url("/img/bike-mundo.svg");
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: 30%;
  background-color: #f9f9f9;
  font-family: Inter !important;
  min-height: 100%;
}

.title {
  margin-top: 1.5rem;
  font-size: 1.5vw;
  font-family: "Inter";
  font-weight: 700;
}

.gray {
  color: #505b71;
}

.green {
  color: #4fa935 !important;
}

.bold {
  font-family: "Inter";
  font-weight: 700;
}

.sub-title {
  color: #6f6c8f;
}

.column-left {
  text-align: center;
  float: left;
  width: 10%;
}

.column-right {
  padding-left: 10px;
  float: left;
  width: 90%;
  color: #78849e;
  font-size: 16px;
  font-weight: normal;
}

.step-by-step:after {
  content: "";
  display: table;
  clear: both;
}

.sub-input {
  color: #6f6c8f;
  font-size: 12px;
}

.input {
  font-size: 16px;
  display: block;
  width: 100%;
  height: 64px;
  border: none;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 10px 16px 0px 16px;
  border-radius: 12px;
  background: #fff;
}

.input-pix {
  font-size: 16px;
  display: block;
  width: 100%;
  height: 64px;
  border: 1px solid #425994;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 16px;
  border-radius: 12px;
  background: #fff;
}

.selected {
  color: black;
  font-weight: 600;
}

.select {
  display: block;
  width: 100%;
  height: 64px;
  border: none;
  padding: 0px 16px;
  border-radius: 12px;
  background: #fff;
  font-size: 14px;
  color: #78849e;
  font-weight: normal;
}

.input::placeholder {
  color: #78849e;
  font-size: 14px;
  font-weight: normal;
}

.confirm-pay {
  font-size: 18px;
  padding: 10px 20px 10px 20px;
  border: 1px solid #4fa935;
  border-radius: 8px;
  background: #4fa935;
  color: #fff;
  box-shadow: 9px 12px 30px 0px rgba(0, 0, 0, 0.1),
    0 6px 20px 0 rgba(0, 0, 0, 0.1);
}

.continue-btn {
  font-weight: bold;
  font-size: 18px;
  padding: 10px 20px 10px 20px;
  border: 1px solid #fff;
  border-radius: 8px;
  background: #fff;
  color: #4fa935;
  box-shadow: 9px 12px 30px 0px rgba(0, 0, 0, 0.1),
    0 6px 20px 0 rgba(0, 0, 0, 0.1);
}

.back-btn {
  border: 1px solid transparent;
  font-size: 18px;
  padding: 10px 20px 10px 20px;
  border-radius: 8px;
  background: #caced9;
  color: #505b71;
}

.continue-btn:disabled,
button[disabled] {
  font-size: 18px;
  padding: 10px 20px 10px 20px;
  border: 1px solid #d1d5dc;
  border-radius: 8px;
  background: #d1d5dc;
  color: #ffffff;
  box-shadow: 9px 12px 30px 0px rgba(0, 0, 0, 0.1),
    0 6px 20px 0 rgba(0, 0, 0, 0.1);
}

.choice-btn {
  padding: 10px 15px 10px 15px;
  border: none;
  border-radius: 8px;
  background: #ffffff;
  color: #089f17;
}

.optional-ghost {
  text-align: center;
  padding: 10px 15px 10px 15px;
  border: none;
  border-radius: 8px;
  background: #ffffff;
  color: #089f17;
  text-wrap: nowrap;
}

.second-choice-btn {
  padding: 10px 15px 10px 15px;
  border: none;
  border-radius: 8px;
  background: #ffffff;
  color: #8a92a0;
}

.ghost {
  text-align: center;
  padding: 10px 15px 10px 15px;
  border: 1px solid #8a92a0;
  border-radius: 8px;
  background: #ffffff;
  color: #8a92a0;
  text-wrap: nowrap;
}

.radius {
  border-radius: 12px !important;
}

.chosen-btn {
  padding: 10px 15px 10px 15px;
  border: none;
  border-radius: 8px;
  background: #78849e;
  color: #ffffff;
}

.steps {
  margin-left: 5%;
  left: 44%;
  right: 57%;
}

.center {
  display: flex;
  align-items: center;
}

.left-side {
  background-color: #fff;
  border-top-right-radius: 32px;
  border-bottom-right-radius: 32px;
}

.row {
  margin: 0;
}

*:focus {
  outline: 0;
}

input:focus::placeholder {
  color: transparent;
}

.invalid-feedback {
  font-size: 14px;
}

@media (max-width: 768px) {
  .continue-btn {
    padding: 9px !important;
    text-wrap: nowrap !important;
    font-weight: bold !important;
  }

  .m-justify-center {
    justify-content: center;
  }

  .font-small {
    font-size: 12px;
  }

  .bg {
    background-image: none !important;
  }

  .w-105 {
    width: 105%;
  }

  .w-100 {
    width: 100%;
  }

  .benefit-content {
    padding-bottom: 40px;
  }

  .tooltip-plan .tooltip-content {
    background-color: #2a2e43;
    color: #fff;
    text-align: left;
    padding: 24px 0 10px 0 !important;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 5;
    width: 100%;
  }

  .card-checkout-title {
    font-size: 5.7vw;
  }

  .card-checkout {
    margin-top: 20px;
    color: #ffffff;
    padding: 24px;
    background-color: #4fa935;
    border-radius: 12px;
    min-height: 0;
  }

  .medium {
    padding-top: 12px;
    font-size: 4.5vw;
    font-weight: 500;
  }

  .big {
    font-size: 50px !important;
  }

  .card-checkout-plan {
    display: block;
  }

  .justify-center {
    justify-content: center;
  }

  .pipe {
    border-left: 0 !important;
    height: 0 !important;
    border-bottom: 2px solid #e5e5e5;
  }

  .card-plan {
    padding-top: 10px !important;
  }

  .border-left-optional {
    border-left: 0 !important;
  }

  .optional-price {
    margin-top: 0 !important;
  }

  .card-optional {
    padding: 0 !important;
    width: 100%;
  }

  .grid {
    display: grid;
  }

  .mr-5 {
    margin-right: 15px !important;
  }

  .sub-title {
    font-size: 16px;
  }

  .desktop {
    display: none !important;
  }

  .title {
    margin-top: 0;
    font-size: 23px;
  }

  .left-side {
    display: none;
  }

  .ml-5,
  .mx-5 {
    margin-left: 0 !important;
  }
}

@media (min-width: 769px) {
  .mobile {
    display: none !important;
  }
}
</style>
