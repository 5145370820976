function listarAnosDe2008AteAtual() {
    const anoAtual = new Date().getFullYear();
    const anos = [];

    for (let ano = anoAtual; ano >= 2008; ano--) {
        anos.push({ anoBike: ano });
    }

    return anos;
}

// Exemplo de uso
const listaAnos = listarAnosDe2008AteAtual();

// Exportando o array atualizado
export default listaAnos;