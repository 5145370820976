<template>
  <div class="minha-conta" :style="cobrancas.length == 0 ? 'height:100vh' : ''">
    <header class="bg-dark py-2">
      <div class="container">
        <div class="row">
          <div class="col">
            <router-link to="/">
              <img src="/img/logo.svg" width="150" height="34" />
            </router-link>
            <span class="badge badge-success ml-2">admin</span>
          </div>

          <div class="col text-right">
            <SideNav />
          </div>
        </div>
      </div>
    </header>
    <div class="bg-gradient">
      <div class="container text-center pt-3">
        <h3>Análise Cobrança Assinatura Registro</h3>
        <div class="h-line" style="width: 142px"></div>
      </div>
    </div>

    <div class="container mt-5 pb-5">
      <div class="row mb-4">
        <div class="col-md-4 mb-2">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1"> Nome </span>
            </div>
            <input type="text" class="form-control" v-model="nome" />
          </div>
        </div>
        <div class="col-md-4 mb-2">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1"> Telefone </span>
            </div>
            <input
              type="text"
              class="form-control"
              v-mask="['(##) ####-####', '(##) #####-####']"
              v-model="telefone"
            />
          </div>
        </div>
        <div class="col-md-4 mb-2">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1"> CPF </span>
            </div>
            <input
              type="text"
              class="form-control"
              v-mask="'###.###.###-##'"
              v-model="cpf"
            />
          </div>
        </div>
        <div class="col-md-4 mb-2">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1">Status</span>
            </div>

            <b-form-select v-model="tipoAssinatura">
              <option :value="null">Todos</option>
              <option :value="config.ID_TIPO_ASSINATURA_MENSAL">Mensal</option>
              <option :value="config.ID_TIPO_ASSINATURA_ANUAL">Anual</option>
            </b-form-select>
          </div>
        </div>

        <div class="col-md-4 mb-2">
          <button
            type="submit"
            class="btn btn-success btn-block mb-3"
            @click.prevent="buscarCobrancas()"
            :disabled="loading"
          >
            <span v-if="!loading">Buscar cobrança</span>
            <span v-if="loading">Por favor aguarde...</span>
          </button>
        </div>
      </div>
    </div>
    <div style="padding-bottom:20px">
    <div class="overflow-auto box-one" v-show="cobrancas.length>0">
      <div v-if="selected.length > 0">
        <div class="text-right mt-3">
          <b-button
            variant="success"
            class="mr-4"
            @click="showModalMensagem = true"
            >Mensagem</b-button
          >
          <b-button
            mcas
            variant="success"
            @click="abrirModalAnaliseCobranca(null)"
            >FeedBack</b-button
          >
        </div>
      </div>
      <b-table
        id="my-table"
        hover
        :items="cobrancas"
        :fields="fields"
        :per-page="perPage"
        :current-page="currentPage"
        small
      >
        <!-- <template #cell(select)="data">
          <b-form-checkbox v-model="selected" :value="data.item">
          Adicione qualquer conteúdo aqui se necessário 
          </b-form-checkbox>
        </template> -->

        <template #cell(urlPagamento)="data">
          <b-button variant="link" @click="copiarLink(data.value)">
            Copiar Link 
          </b-button>
        </template>
        <template #cell(idPagamentoAssinaturaRegistro)="data">
          <b-button @click.prevent="abrirModalAnaliseCobranca(data.value)" variant="success"
            ><i class="fas fa-plus fa-fw"></i
          ></b-button>
        </template>
        <template #cell(idPagamento)="data">
          <b-button
            @click.prevent="abrirHistorico(data.value)"
            variant="success"
            ><i class="fa fa-list"><span class="historico">{{verificaQuantidade(data.value)}}</span></i
          ></b-button>
        </template>
      </b-table>
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        pills
        aria-controls="my-table"
      ></b-pagination>
    </div></div>
    <div>
      <Loading :criandoSeguro="true" v-if="loading" />
      <ModalHistoricoCobrancaRegistro
        :showModalHistorico="showModalHistorico"
        :idCobranca="idCobranca"
        @hide="fecharModalHistorico"
      />
      <ModalAnaliseCobrancaRegistro
        :selected="selected"
        :showModal="showModal"
        :idCobranca="idCobranca"
        @hide="fecharModalAnaliseCobranca"
        @hideModalF="fecharModalAnaliseCobrancaVarios"
      />
      <ModalMensagem
        :selected="selected"
        :showModal="showModalMensagem"
        :idCobranca="idCobranca"
        @hide="fecharModalMensagem"
      />
    </div>
  </div>
</template>

<script>
import SideNav from "../../components/Admin/SideNav";
import config from "../../config";
import auth from "../../auth";
import Swal from "sweetalert2";
import axios from "axios";
import formatacaoMoeda from "../../helpers/formatacaoMoeda";
import formatacaoData from "../../helpers/formatacaoData";
import formatacaoTelefone from "../../helpers/formatacaoTelefone";
import helper from "../../helpers/helper";
import ModalHistoricoCobrancaRegistro from "../../components/Admin/ModalHistoricoCobrancaAssinaturaRegistro.vue";
import ModalAnaliseCobrancaRegistro from "../../components/Admin/ModalAnaliseCobrancaAssinaturaRegistro.vue";
import ModalMensagem from "../../components/Admin/ModalMensagem.vue";
import Loading from "../../components/Usuario/Loading.vue";
import moment from "moment";
export default {
  components: {
    SideNav,
    ModalHistoricoCobrancaRegistro,
    ModalAnaliseCobrancaRegistro,
    ModalMensagem,
    Loading,
  },
  data() {
    return {
      config,
      loading: false,
      cobrancas: [],
      selected: [],
      idUsuarioAdm: undefined,
      formatacaoMoeda,
      formatacaoData,
      formatacaoTelefone,
      nome: null,
      email: null,
      telefone: null,
      tipoAssinatura: null,
      cpf: null,
      helper,
      loadingText: false,
      showModalMensagem: false,
      //Modal
      showModalHistorico: false,
      idCobranca: undefined,

      showModal: false,

      fields: [
        // {
        //   key: "select",
        //   label: "",
        //   sortable: false,
        //   thClass: "text-center",
        //   tdClass: "text-center",
        // },
        {
          key: "tipoAssinatura",
          label: "Assinatura",
          sortable: true,
        },
        {
          key: "dataCriado",
          label: "Data Vencido",
          formatter: (value) => {
            return moment(value, "YYYY-MM-DD").format("DD/MM/YYYY");
          },
        },
        {
          key: "nome",
          label: "Nome",
          sortable: true,
        },
        {
          key: "email",
          label: "Email",
        },
        {
          key: "cpf",
          label: "Cpf",
          formatter: (value) => {
            return value?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
          },
        },

        {
          key: "telefone",
          label: "Telefone",
          formatter: (value) => {
            if (value){
            value=  value?.replace(/^(\d{2})(\d{5})(\d{4})$/, "($1) $2-$3");
            } 
            return value;
          },
          sortable: true,
        },
        {
          key: "valor",
          label: "Valor",
          formatter: (value) => {
            return (
              "R$ " +
              value?.toLocaleString("pt-BR", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })
            );
          },
        },
        {
          key: "urlPagamento",
          label: "Fatura",
        },
        {
          key: "idPagamentoAssinaturaRegistro",
          label: "Ações",
        },
        {
          key: "idPagamento",
          label: "Historico",
        },
      ],
      
      currentPage: 1,
      perPage: 10,
      items: [],
      checkouts: {},
    };
  },
  created() {
    var userInfo = auth.getUserInfo();
    if (userInfo && userInfo.cargo) {
      this.idUsuarioAdm = userInfo.id;
    } else {
      this.$router.replace("/login");
    }
  },
   computed: {
    rows() {
      return this.cobrancas.length;
    },
  },
  mounted() {
    this.buscarCobrancas();
  },
  methods: {
    verificaQuantidade(value){
      return this.cobrancas.find(w=>w.idPagamento ==value ).quantidade;
    },
    copiarLink(link) {
      navigator.clipboard.writeText(link);
      this.$bvToast.toast("Link copiado para a área de transferência!", {
        title: "Sucesso",
        variant: "success",
        solid: true,
      });
    },
    ordenarMaiorValor() {
      this.cobrancas.sort((a, b) => a.valor - b.valor);
    },
    ordenarMenorValor() {
      this.cobrancas.sort((a, b) => b.valor - a.valor);
    },
    fecharModalHistorico() {
      this.showModalHistorico = false;
    },
    abrirHistorico(item) {
      this.showModalHistorico = true;
      this.idCobranca = item;
    },
    abrirModalAnaliseCobranca(item) {
      this.showModal = true;
      this.idCobranca = item;
    },
    fecharModalAnaliseCobranca(value) {
      this.showModal = false;
      if (value?.modificou) {
        this.cobrancas.forEach((element) => {
          if (element.idPagamento === value.idCobranca) {
            element.quantidade++;
          }
        });
      }
    },
    fecharModalAnaliseCobrancaVarios(values) {
      this.showModal = false;
      if (values?.modificou) {
        this.cobrancas.forEach((element) => {
          if (
            values.cobrancas.find((w) => w.idPagamento == element.idCobranca)
          ) {
            element.quantidade++;
          }
        });
        this.selected = [];
      }
    },
    fecharModalMensagem(values) {
      this.showModalMensagem = false;

      if (values?.modificou) {
        this.cobrancas.forEach((element) => {
          const cobranca = values.cobrancas.find(
            (w) => w.idPagamento == element.idCobranca
          );
          if (cobranca != null) {
            element.quantidade++;
            element.erroEnvioMensagem = cobranca.erroEnvioMensagem;
          }
        });
        this.selected = [];
      }
    },
    async buscarCobrancas() {
      var data = {
        email: this.email,
        nome: this.nome,
        telefone: this.telefone,
        cpf: this.cpf,
        tipoAssinatura: this.tipoAssinatura,
      };

      this.loading = true;
      try {
        var response = await axios.post(
          `${config.API_URLV2}/AnaliseCobrancaPagamentoAssinaturaRegistro/Buscar`,
          data
        );

        if (response.data.length === 0) {
          Swal.fire({
            icon: "warning",
            title: "Oops...",
            html: `Não foi encontrada nenhuma cobrança para essa pesquisa`,
          });
        }

        this.cobrancas = response.data;
        this.selected = [];

        this.loading = false;
      } catch (error) {
        this.loading = false;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Não foi possível consultar as cobranças. Tente novamente mais tarde ou contate o suporte.",
        });
      }
    },
    async buscarLinkFatura(urlPagamento) {
      window.open(urlPagamento, "_blank");
    },
  },
};
</script>
<style>
.table th {
  vertical-align: top;
  border-top: 1px solid #ffffff;
}
.table-sm th,
.table-sm td {
  padding: 1rem;
}
</style>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

.historico {
  float: inline-end;
    margin-top: -8px;
    font-size: 9px;
    margin-right: -10px;
}

.box-one {
  border-radius: 12px;
  cursor: pointer;
  text-align: center;
  padding: 10px;
  margin-left: 20px;
  margin-right: 20px;
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
  background-color: #fff;
  color: #252422;
  margin-bottom: 20px;
  position: relative;
  border: 0;
  transition: box-shadow 0.2s ease,
    -webkit-transform 0.3s cubic-bezier(0.34, 2, 0.6, 1);
  transition: transform 0.3s cubic-bezier(0.34, 2, 0.6, 1), box-shadow 0.2s ease;
  transition: transform 0.3s cubic-bezier(0.34, 2, 0.6, 1), box-shadow 0.2s ease,
    -webkit-transform 0.3s cubic-bezier(0.34, 2, 0.6, 1);
}
.minha-conta {
  font-family: "Montserrat";
  background-color: #f4f3ef;
  transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
}
.bg-gradient {
  background: rgb(0, 0, 0);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.14609593837535018) 0%,
    rgba(255, 255, 255, 0) 70%
  );
}
.pagination {
  justify-content: center;
}
.icone-principal {
  font-size: 43px;
  color: #28a745;
  background: -webkit-linear-gradient(#76b566, #28a745);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
hr {
  margin-top: 10px;
  margin-bottom: 15px;
}

.not-collapsed .when-closed,
.collapsed .when-open {
  display: none;
}
</style>
