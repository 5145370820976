<template>
  <b-modal
    :visible="showModalHistorico"
    title="Histórico Cobrança"
    :hide-footer="true"
    @hide="hideModal"
    size="lg"
  >
    <div class="row" v-if="!loading">
      <div class="col-lg-12 order-lg-2">
        <div v-if="historico.length == 0">
          <p class="text-center">Cobrança sem feedbacks cadastrados</p>
        </div>
        <div v-else>
          <div>
            <p>Número de ligações: {{ historico.length }}</p>
          </div>
          <table class="table text-center">
            <thead>
              <tr>
                <th>Data da ligação</th>
                <th>Feedback</th>
                <th>Criado por</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in historico" :key="index">
                <td>
                  {{ formatacaoData.formatarDataHoraLocal(item.dataCriado) }}
                </td>
                <td>{{ item.feedBack }}</td>
                <td>{{ item.nomeUsuario }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div v-else class="text-center">
      <lottie-player
        src="/anim/mountain_bike.json"
        background="transparent"
        speed="1.7"
        style="width: 72px; height: 72px; margin:0 auto;"
        autoplay
        loop
        class="mt-3"
      ></lottie-player>
      <div>Carregando, por favor aguarde...</div>
    </div>
  </b-modal>
</template>

<script>
import Swal from "sweetalert2";
import axios from "axios";
import config from "../../config";
import captureError from "../../helpers/captureError";
import formatacaoData from "../../helpers/formatacaoData";

export default {
  props: ["showModalHistorico", "idCobranca"],
  data() {
    return {
      historico: [],
      config,
      formatacaoData,
      loading: false,
    };
  },
  watch: {
    showModalHistorico: function(newValue, oldValue) {
      if (newValue != oldValue && newValue) {
        this.buscarHistorico();
      }
    },
  },
  methods: {
    hideModal() {
      this.$emit("hide");
    },
    async buscarHistorico() {
      if (!this.idCobranca) return;

      this.loading = true;
      try {
        var response = await axios.get(
          `${config.API_URLV2}/AnaliseCobrancaPagamentoAssinaturaRegistro/historico?idCobranca=${this.idCobranca}`
        );
        this.historico = response.data;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        captureError(error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text:
            "Não foi possível consultar o histórico dessa cobrança. Tente novamente mais tarde ou contate o suporte.",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
