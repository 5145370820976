<template>
  <b-modal :visible="showModal" title="Cadastrar histórico de feedback" :hide-footer="true" @hide="hideModal" size="lg">
    <div class="row">
      <div class="col-lg-12 order-lg-2">
        <ValidationObserver v-slot="{ handleSubmit, invalid }">
          <form class="mt-2" @submit.prevent="handleSubmit(salvar)">
            <div class="form-group">
              <ValidationProvider name="feedBack" rules="required" v-slot="{ errors, classes }">
                <label>Feedback</label>
                <textarea class="form-control" :class="classes" v-model="feedBack" rows="3">
                </textarea>
                <span class="invalid-feedback">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div class="row">
              <div class="col-6">
                <button type="submit" class="btn btn-success btn-block" :disabled="invalid || loadingText">
                  <span v-if="!loadingText">Salvar feedBack</span>
                  <span v-else>Por favor aguarde...</span>
                </button>
              </div>
              <div class="col-6">
                <button type="submit" class="btn btn-danger btn-block" @click="hideModal" :disabled="loadingText">
                  <span>Cancelar</span>
                </button>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Swal from "sweetalert2";
import axios from "axios";
import config from "../../config";
import captureError from "../../helpers/captureError";
import formatacaoData from "../../helpers/formatacaoData";

export default {
  props: ["selected", "showModal", "idCobranca"],
  data() {
    return {
      historico: [],
      config,
      formatacaoData,
      loading: false,
      feedBack: "",
      loadingText: false,
    };
  },

  methods: {
    hideModal(value) {
      this.$emit("hide", { modificou: value != true ? false : value, idCobranca: this.idCobranca });
      this.feedBack = "";
    },
    hideModalF(value) {
      this.$emit("hideModalF", { modificou: value != true ? false : value, cobrancas: this.selected });
      this.feedBack = "";
    },
    async salvarVarios() {
      this.loadingText = true;
      try {
        var idCobrancas = this.selected.map((item) => item.idCobranca);
        var strDaadosUserInfor = localStorage.getItem("UserInfo");
        var dados = JSON.parse(strDaadosUserInfor);
        var data = {
          IdPagamentoAssinaturaRegistro: this.idCobranca,
          idUsuario: dados.id,
          nomeUsuario: dados.login,
          feedBack: this.feedBack,
          idCobrancas: idCobrancas
        };
        await axios.post(`${config.API_URLV2}/AnaliseCobrancaPagamentoAssinaturaRegistro/analiseCobrancas`, data);
        Swal.fire(
          "Feedback",
          "O Feedback foi cadastrado com sucesso!!",
          "success"
        );

      } catch (error) {
        this.loadingText = false;
        captureError(error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text:
            "Não foi possível consultar as cobranças. Tente novamente mais tarde ou contate o suporte.",
        });
      }
    },
    async salvar() {
      if (this.selected.length > 0) {
        await this.salvarVarios();
        this.hideModalF(true);
        this.loadingText = false;
        return;
      }
      this.loadingText = true;
      try {
        var strDaadosUserInfor = localStorage.getItem("UserInfo");
        var dados = JSON.parse(strDaadosUserInfor);
        var data = {
          IdPagamentoAssinaturaRegistro: this.idCobranca,
          idUsuario: dados.id,
          nomeUsuario: dados.login,
          feedBack: this.feedBack,
        };
        await axios.post(`${config.API_URLV2}/AnaliseCobrancaPagamentoAssinaturaRegistro`, data);
        Swal.fire(
          "Feedback",
          "O Feedback foi cadastrado com sucesso!!",
          "success"
        );
        this.hideModal(true);
        this.loadingText = false;
      } catch (error) {
        this.loadingText = false;
        captureError(error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text:
            "Não foi possível consultar as cobranças. Tente novamente mais tarde ou contate o suporte.",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
